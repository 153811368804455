import styled from 'styled-components';

export const TitleContainer = styled.div`
  height: 430px;
  background: #fff url(${(props) => props.$backgroundImage}) repeat-x bottom
    center;
  color: #6e2b8b;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.02em;
`;

export const Title = styled.div`
  width: 800px;
  height: auto;
  margin: 0 auto;
  padding: 40px 0px 0px 0px;
  text-align: center;
  @media (max-width: 991px) {
    width: 100%;
    height: auto;
    padding: 30px 35px 10px 35px;
    text-align: center;
  }
`;

export const HeaderH1 = styled.h1`
  font-size: 45px;
  line-height: 48px;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 15px;
  margin: 0 0 35px 0;
  padding: 0;
  font-family: 'Figtree-Medium', arial, sans-serif;
  font-weight: normal !important;
  color: #6e2b8b;
  @media (max-width: 991px) {
    font-size: 32px;
    line-height: 38px;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 0;
  }
  b {
    font-family: 'Figtree-Bold', arial, sans-serif;
    font-weight: bold;
  }
`;
